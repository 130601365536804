<template>
  <v-container class="content ma-0 pa-4">
    <v-row
      v-if="loading"
      justify="center"
      align="center"
      class="pa-4"
      style="height: 50vh"
    >
      <div style="text-align: center">
        <v-progress-circular :size="70" color="primary" indeterminate />
        <h3 class="mt-4">Preparando visualização...</h3>
      </div>
    </v-row>
    <v-row v-else>
      <v-col activatable cols="2">
        <v-header>Projetos</v-header>
        <v-list shaped>
          <v-list-item-group v-model="projectSelected" color="primary">
            <v-list-item v-for="(item, i) in projectsstruture" :key="i">
              <v-list-item-content @click="reset">
                <v-list-item-title v-text="item.name"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-col>
      <v-divider vertical></v-divider>
      <v-col cols="6">
        <v-header>Relatórios</v-header>
        <v-treeview
          hoverable
          activatable
          return-object
          :items="project"
          item-key="treeViewId"
          item-text="treeViewText"
          item-children="treeViewChildren"
          @update:active="getSeletectItem"
        >
        </v-treeview>
      </v-col>
      <v-divider vertical></v-divider>
      <v-col cols="3">
        <v-header>Campos</v-header>
        <div v-if="!selectedItem" style="align-self: center">
          Selecione um campo
        </div>
        <v-card v-else :key="selectedItem.id" flat class="ma-0">
          <v-card-text>
            <div v-if="selectedItem.title" class="border-field">
              <v-text-field
                v-model="selectedItem.title"
                dense
                hide-details
                label="Título"
              />
            </div>
            <div v-if="selectedItem.subtitle" class="border-field">
              <v-text-field
                v-model="selectedItem.subtitle"
                dense
                hide-details
                label="Descrição"
              />
            </div>
            <div v-if="selectedItem.active !== undefined" class="border-field">
              <v-checkbox
                v-if="selectedItem.active !== undefined"
                v-model="selectedItem.active"
                label="Ativo"
                dense
                hide-details
              ></v-checkbox>
            </div>
            <div v-if="selectedItem.label" class="border-field">
              <v-text-field
                v-model="selectedItem.label"
                dense
                hide-details
                label="Título"
              />
            </div>
            <div v-if="selectedItem.placeholder" class="border-field">
              <v-text-field
                v-model="selectedItem.placeholder"
                dense
                hide-details
                label="Placeholder"
              />
            </div>
            <div v-if="selectedItem.name" class="border-field">
              <v-text-field
                v-model="selectedItem.name"
                dense
                hide-details
                label="Nome"
              />
            </div>
            <div v-if="selectedItem.componentType" class="border-field">
              <v-text-field
                v-model="selectedItem.componentType"
                dense
                hide-details
                label="Tipo de Componente"
              />
            </div>
            <div v-if="selectedItem.type" class="border-field">
              <v-text-field
                v-model="selectedItem.type"
                dense
                hide-details
                label="Tipo"
              />
            </div>
            <div v-if="selectedItem.mask" class="border-field">
              <v-text-field
                v-model="selectedItem.mask"
                dense
                hide-details
                label="Máscara"
              />
            </div>
            <div v-if="selectedItem.selectOptions" class="border-field">
              <h5>Opções</h5>
              <div v-if="selectedItem.selectOptions.key" class="border-field">
                <v-text-field
                  v-model="selectedItem.selectOptions.key"
                  dense
                  hide-details
                  label="Chave"
                />
              </div>
              <div v-if="selectedItem.selectOptions.value" class="border-field">
                <v-text-field
                  v-model="selectedItem.selectOptions.value"
                  dense
                  hide-details
                  label="Valor"
                />
              </div>
              <div
                v-if="selectedItem.selectOptions.entity"
                class="border-field"
              >
                <v-text-field
                  v-model="selectedItem.selectOptions.entity"
                  dense
                  hide-details
                  label="Entidade"
                />
              </div>
            </div>
            <div v-if="selectedItem.MultInsertForm" class="border-field">
              <h5>Formulário</h5>
              <div v-if="selectedItem.MultInsertForm.key" class="border-field">
                <v-text-field
                  v-model="selectedItem.MultInsertForm.key"
                  dense
                  hide-details
                  label="Chave"
                />
              </div>
              <div
                v-if="selectedItem.MultInsertForm.title"
                class="border-field"
              >
                <v-text-field
                  v-model="selectedItem.MultInsertForm.title"
                  dense
                  hide-details
                  label="Título"
                />
              </div>
              <div
                v-if="selectedItem.MultInsertForm.subtitle"
                class="border-field"
              >
                <v-text-field
                  v-model="selectedItem.MultInsertForm.subtitle"
                  dense
                  hide-details
                  label="Subtítulo"
                />
              </div>
            </div>
            <div v-if="selectedItem.options" class="border-field">
              <h5>Opções</h5>
              <div v-for="(option, index) in selectedItem.options" :key="index">
                <div v-if="option.label" class="border-field">
                  <v-text-field
                    v-model="option.label"
                    dense
                    hide-details
                    label="Descrição"
                  />
                </div>
                <div v-if="option.value" class="border-field">
                  <v-text-field
                    v-model="option.value"
                    dense
                    hide-details
                    label="Valor"
                  />
                </div>
                <div v-if="option.componentType" class="border-field">
                  <v-text-field
                    v-model="option.componentType"
                    dense
                    hide-details
                    label="Tipo de componente"
                  />
                </div>
              </div>
            </div>
            <div v-if="selectedItem.rules" class="border-field">
              <h5>Regras</h5>
              <div
                v-for="(rule, indexR) in selectedItem.rules"
                :key="indexR"
                class="margin-option"
              >
                <div v-if="isRule(rule, 'required')" class="border-field">
                  <v-checkbox
                    v-if="isRule(rule, 'required')"
                    v-model="rule.value"
                    :true-value="'true'"
                    :false-value="'false'"
                    :label="getLabelFromRule('required')"
                    dense
                    hide-details
                  ></v-checkbox>
                </div>
                <div
                  v-if="isRule(rule, 'permitirDataFutura')"
                  class="border-field"
                >
                  <v-checkbox
                    v-if="isRule(rule, 'permitirDataFutura')"
                    v-model="rule.value"
                    :true-value="'true'"
                    :false-value="'false'"
                    :label="getLabelFromRule('permitirDataFutura')"
                    dense
                    hide-details
                  ></v-checkbox>
                </div>
                <div
                  v-if="isRule(rule, 'naoPermitirDataAnterior')"
                  class="border-field"
                >
                  <v-checkbox
                    v-if="isRule(rule, 'naoPermitirDataAnterior')"
                    v-model="rule.value"
                    :true-value="'true'"
                    :false-value="'false'"
                    :label="getLabelFromRule('naoPermitirDataAnterior')"
                    dense
                    hide-details
                  ></v-checkbox>
                </div>
                <div v-if="isRule(rule, 'validateCPF')" class="border-field">
                  <v-checkbox
                    v-if="isRule(rule, 'validateCPF')"
                    v-model="rule.value"
                    :true-value="'true'"
                    :false-value="'false'"
                    :label="getLabelFromRule('validateCPF')"
                    dense
                    hide-details
                  ></v-checkbox>
                </div>
                <div v-if="isRule(rule, 'validateCNPJ')" class="border-field">
                  <v-checkbox
                    v-if="isRule(rule, 'validateCNPJ')"
                    v-model="rule.value"
                    :true-value="'true'"
                    :false-value="'false'"
                    :label="getLabelFromRule('validateCNPJ')"
                    dense
                    hide-details
                  ></v-checkbox>
                </div>
                <div v-if="isRule(rule, 'editable')" class="border-field">
                  <v-checkbox
                    v-if="isRule(rule, 'editable')"
                    v-model="rule.value"
                    :true-value="'true'"
                    :false-value="'false'"
                    :label="getLabelFromRule('editable')"
                    dense
                    hide-details
                  ></v-checkbox>
                </div>
                <div
                  v-if="isRule(rule, 'minimumTimePiker')"
                  class="border-field"
                >
                  <h5>{{ getLabelFromRule('minimumTimePiker') }}</h5>
                  <div
                    v-for="(minimum, indexM) in rule.value"
                    :key="indexM"
                    class="margin-option"
                  >
                    <div
                      v-if="isRule(minimum, 'minimumDate')"
                      class="border-field"
                    >
                      <v-text-field
                        v-model="minimum.value"
                        dense
                        hide-details
                        :label="getLabelFromRule('minimumDate')"
                      />
                    </div>
                    <div
                      v-if="isRule(minimum, 'minimumTime')"
                      class="border-field"
                    >
                      <v-text-field
                        v-model="minimum.value"
                        dense
                        hide-details
                        :label="getLabelFromRule('minimumTime')"
                      />
                    </div>
                  </div>
                </div>
                <div
                  v-if="isRule(rule, 'fieldCalculated')"
                  class="border-field"
                >
                  <v-text-field
                    v-model="rule.value"
                    dense
                    hide-details
                    :label="getLabelFromRule('fieldCalculated')"
                  />
                </div>
                <div v-if="isRule(rule, 'maxLength')" class="border-field">
                  <v-text-field
                    v-model="rule.value"
                    dense
                    hide-details
                    :label="getLabelFromRule('maxLength')"
                  />
                </div>
                <div v-if="isRule(rule, 'size')" class="border-field">
                  <v-text-field
                    v-model="rule.value"
                    dense
                    hide-details
                    :label="getLabelFromRule('size')"
                  />
                </div>
                <div v-if="isRule(rule, 'min')" class="border-field">
                  <v-text-field
                    v-model="rule.value"
                    dense
                    hide-details
                    :label="getLabelFromRule('min')"
                  />
                </div>
                <div v-if="isRule(rule, 'max')" class="border-field">
                  <v-text-field
                    v-model="rule.value"
                    dense
                    hide-details
                    :label="getLabelFromRule('max')"
                  />
                </div>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      loading: true,
      active: [],
      open: [],
      selectedItem: null,
      projectSelected: -1,
      projectsstruture: [],
      rulesConst: [
        { key: 'required', label: 'Requerido' },
        { key: 'permitirDataFutura', label: 'Permitir data futura' },
        { key: 'naoPermitirDataAnterior', label: 'Não permitir data anterior' },
        { key: 'fieldCalculated', label: 'Campo calculado' },
        { key: 'minimumTimePiker', label: 'Menor data permitida' },
        { key: 'minimumDate', label: 'Campo data' },
        { key: 'minimumTime', label: 'Campo hora' },
        { key: 'validateCPF', label: 'Validar CPF' },
        { key: 'validateCNPJ', label: 'Validar CNPJ' },
        { key: 'editable', label: 'Editável' },
        { key: 'maxLength', label: 'Tamanho máximo' },
        { key: 'size', label: 'Tamanho' },
        { key: 'min', label: 'Mínimo' },
        { key: 'max', label: 'Máximo' },
      ],
    }
  },
  computed: {
    project() {
      let proj = []
      if (this.projectSelected >= 0) {
        proj = this.jsonToTreeView(
          JSON.parse(this.projectsstruture[this.projectSelected].dados),
        )
      }
      return proj
    },
  },
  created() {
    this.getProjectsStructure()
  },
  methods: {
    getProjectsStructure() {
      this.loading = true
      this.api.get.projectsstructure().then(data => {
        this.projectsstruture = data
        this.loading = false
      })
    },
    jsonToTreeView(dados) {
      let init = 0

      const fieldsToChildren = function (fields) {
        if (fields && fields.length) {
          fields.forEach(c => {
            c.treeViewId = init++
            c.treeViewChildren = []
            if (c.title) {
              c.treeViewText = c.title
            } else if (c.label) {
              c.treeViewText = c.label
            }
            if (c.fields && c.fields.length) {
              c.treeViewChildren = fieldsToChildren(c.fields)
            }
          })
        }
        return fields
      }

      if (dados && dados.length) {
        dados.forEach(p => {
          p.treeViewId = init++
          p.treeViewText = p.title
          p.treeViewChildren = []
          if (p.steps && p.steps.length) {
            p.treeViewChildren = fieldsToChildren(p.steps)
          }
        })
      }
      return dados
    },
    getSeletectItem(value) {
      this.selectedItem = value[0]
    },
    reset() {
      this.selectedItem = null
    },
    hasRuleInConst(value) {
      return this.rulesConst.filter(el => el.key === value).length > 0
    },
    isRule(rule, value) {
      return this.hasRuleInConst(value) && rule.key === value
    },
    getLabelFromRule(value) {
      let rules = this.rulesConst.filter(el => el.key === value)
      return rules.length > 0 ? rules[0].label : ''
    },
  },
}
</script>

<style scoped>
.border-field {
  border: solid 1px #999;
  border-radius: 5px;
  padding: 10px 5px 5px 5px;
  margin-bottom: 5px;
}

.margin-option {
  margin-bottom: 5px;
}

.v-input--selection-controls {
  margin-top: 0px;
}
</style>
